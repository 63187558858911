import { Fragment } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector } from "react-redux";

export default function PeopleNav() {
    const user = useSelector(state => state.user)
    
    const { PEOPLE_TEST, TEAM_HR, TOP_HR } = user

    const peopleNav = //PEOPLE_TEST === 1 ? 
    <Fragment>
        <Dropdown.Divider />
        <Dropdown.Header>Magnify People Reports</Dropdown.Header>
        {TOP_HR === 1 ? <Dropdown.Item href="/reports/reviews/firm">Firm Wide Reviews</Dropdown.Item> : <Fragment></Fragment>}
        {TOP_HR === 1 ? <Dropdown.Item href='/reports/morale/firm'>Firm Wide Check Ins</Dropdown.Item> : <Fragment></Fragment>}
        {TEAM_HR === 1 ? <Dropdown.Item href="/reports/reviews/team">Team Reviews</Dropdown.Item> : <Fragment></Fragment>}
        {TEAM_HR === 1 ? <Dropdown.Item href='/reports/morale/team'>Team Check Ins</Dropdown.Item> : <Fragment></Fragment>}
    </Fragment> 
    // : <Fragment></Fragment>

    // if (PEOPLE_TEST === 1) {
      return (
        <Dropdown>
            <Dropdown.Toggle variant="primary-bg brand-text">
                Magnify People
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item href="/people/reviews">Reviews</Dropdown.Item>
                {/* <Dropdown.Item href="/people/requests">Requests</Dropdown.Item> */}
                { TEAM_HR === 1 ? <Dropdown.Item href="/people/reviewapproval">Approve Team Reviews</Dropdown.Item> : <Fragment></Fragment>}
                <Dropdown.Divider />
                <Dropdown.Item href='/people/morale'>Weekly Check In</Dropdown.Item>
                {peopleNav}
            </Dropdown.Menu>
        </Dropdown>
      )
    // } else {
    //     <Fragment></Fragment>
    // }
}
