import { Fragment } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector } from "react-redux";

export default function PowerBINav() {
    const { POWER_BI, PEOPLE_TEST, TEAM_HR, TOP_HR } = useSelector(state => state.user)

    const powerBiNav = POWER_BI === 1 ? <Fragment>
        <Dropdown.Header>Power BI Reports</Dropdown.Header>
        <Dropdown.Item href="/reports/powerbi/member">BH Dashboard</Dropdown.Item>
        <Dropdown.Item href="https://app.powerbi.com/links/3mAvM2s4iF?ctid=e3ddacec-0f4b-42ac-be19-d4c0fd99f77d&pbi_source=linkShare">EXTERNAL Link to Power BI</Dropdown.Item>
    </Fragment> : <Fragment></Fragment>

    return (
        <Fragment>
            {powerBiNav}
        </Fragment>

    )
}